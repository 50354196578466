.login-vertical-center {
  margin-top: 20%;
}
.login-section {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
}

.mg-auto {
  margin: auto;
}

.brand-logo {
  max-height: 70px;
}
.brand-logo-right {
  max-height: 70px;
  position: absolute;
  top: 10px;
}
.login-btn {
  width: 80px;
}
.top-link,
.original-site-link {
  z-index: 999;
  left: 40px;
}

.sideLinks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 15px 25px;
}
.audio-cardio-m-b {
  position: relative;
  top: -55px;
}

.audio-cardio-m-b-signup {
  padding-top: 5px;
}
.signin-bg {
  z-index: -10;
  min-width: 100vw;
  min-height: 100vh;
  background-image: url("../assets/images/sign-up-bg.png");
  overflow-x: hidden;
  background-size: cover;
}
.intro-text {
  margin-top: 10px;
  margin-bottom: 4px;
  font-size: 16px;
}
.discover-audio-cardio {
  font-size: 30px;
  font-weight: 700;
  .left {
    color: #f76860;
  }
  .right {
    color: #21253b;
  }
}
.start-free-trial {
  color: #f76860;
  font-weight: 700;
  font-size: 23px;
  margin-top: 30px;
  @media (max-width: 700px) {
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 18px;
  }
}
.branding-section {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  .branding-card {
    display: flex;
    flex-direction: column;
    max-width: 425px;
    padding-left: 15px;
    ul {
      list-style: none;
      color: #21253b;
      font-weight: 600;
      font-size: 14px;
      li {
        margin-bottom: 4px;
      }
    }
    ul li::before {
      content: "\2022";
      color: #40cd88;
      font-weight: 900;
      font-size: 18px;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }
}
.m-0 {
  margin: 0px;
}
.mt-10 {
  margin-top: 25px;
  @media (max-width: 600px) {
    margin: 0;
    margin-top: 10px;
  }
}

.owlImage {
  width: 30%;
  margin-right: 10px;
  @media (max-width: 762px) {
    display: none;
  }
}

.branding-card-stretch {
  margin-top: 20px;
}
.login-section-height {
  height: 70px;
}

.login-Btn-facebook-pR {
  padding-right: 10px;
}

.discover-audio-cardio-benefits {
  font-size: 20px;
  .margin-bottom-benefits {
    margin-bottom: 0px !important;
    margin-left: 16px;
  }
  @media (max-width: 600px) {
    font-size: 18px;
  }
}

.semi-bold-hk {
  font-family: "HK Grotesk Semibold";
}

.branding-card-logo {
  width: 360px;
}

.mt-auto {
  display: block;
  margin: auto;
}
.custom-line-height {
  line-height: 40px;
}

@media only screen and (max-width: 700px) {
  .login-section {
    width: 250px;
    margin: auto;
  }
}
