.heading-margin {
  margin-top: 20px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 600px) {
  .subscriptions-layout {
    display: none;
  }
}
@media only screen and (min-width: 600px) {
  .subscriptions-mobile-design {
    display: none;
  }
}
.update-btn {
  display: flex;
  justify-content: space-between;
}
.font-15 {
  font-size: 15px;
}

.mr-4 {
  margin-right: 4px;
}
