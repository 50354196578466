.count-card {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  .heading {
    background-color: rgba(255, 255, 255, 0);
    font-size: 14px;
    color: rgba(153, 153, 153, 1);
    padding-left: 10px;
    text-transform: uppercase;
  }
  .count {
    font-size: 2rem;
    color: #333333;
    padding-left: 10px;
    line-height: 4rem;
    padding-left: 10px;
  }
  .count-icon {
    font-size: 30px;
    margin-top: 12px;
  }
  .ant-card-body {
    padding: 14px;
  }
}

