.profile-picture {
  margin-right: 10px;
  margin-left: 10px;
}
.username {
  color: $solid-black;
  margin-right: 8px;
}
.header-logo {
  height: 60px;
}

