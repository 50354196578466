.input-form {
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #333333;
    border-color: #333333;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #333333;
  }
  .ant-input {
    border-radius: 20px !important;
    border: solid 1px #e3e3e3;
    background-color: #ffffff !important;
    height: 38px !important;
  }
  label {
    font-size: 16px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #333333 !important;
  }
  .ant-form-item-required::before {
    color: #333333 !important;
  }
  .ant-select-selection {
    border: solid 1px #e3e3e3 !important;
    background-color: #ffffff !important;
    height: 40px !important;
    border-radius: 20px !important;
  }

  .ant-select-selection--multiple .ant-select-selection__rendered {
    margin-top: 8px;
  }
  .ant-select-selection__rendered {
    margin-top: 8px;
  }
  .ant-select-selection__placeholder {
    top: 38% !important;
  }
}

