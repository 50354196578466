.custom-layout {
  .ant-layout-header {
    background: #fff;
    -webkit-box-shadow: 0 2px 8px #f0f1f2;
    box-shadow: 0 2px 8px #f0f1f2;
  }
  .ant-layout {
    //background: rgba(250, 249, 255, 0.6);
    background: #e7e7ef;
  }
  .layout-container {
    height: calc(100vh - 64px);
    overflow: scroll;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 10px;
  }
  @media only screen and (max-width: 600px) {
    .layout-container {
      overflow: scroll;
      padding-left: 2px;
      padding-right: 2px;
      padding-top: 10px;
    }
  }
}

.custom-table {
  table {
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  table td,
  table th {
    border: 0px solid #ddd;
    padding: 8px;
  }

  table tr:hover {
    background-color: #ddd;
  }

  table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #f2f2f2 !important;
  }
}

