.loginBtn {
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 1.2em;
  font-size: 16px;
  color: #fff;
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 17px;
  height: 38px;
}
.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 44px;
  height: 100%;
}
.loginBtn:focus {
  outline: none;
}
.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

.loginBtn--facebook {
  background-color: #4c69ba;
  background-image: linear-gradient(#4c69ba, #3b55a0);
  text-shadow: 0 -1px 0 #354c8c;
}
.loginBtn--facebook:before {
  background-image: url("../assets/images/login-FB.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 28px 28px;
  margin-left: 14px;
}
.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background-color: #5b7bd5;
  background-image: linear-gradient(#5b7bd5, #4864b1);
}

.loginBtn--google {
  background: #3f86f4;
}
.loginBtn--google:before {
  background-image: url("../assets/images/login-G.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 28px 28px;
  margin-left: 14px;
}
.loginBtn--google:hover,
.loginBtn--google:focus {
  background: rgba(63, 134, 244, 0.67);
}

