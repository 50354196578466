/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 12px 12px;
  border: 1px solid transparent;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}
.StripeElement::placeholder {
  color: white;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
.stripe-pay-btn {
  color: white;
  background: #f76860;
  border: 4px;
  width: 50%;
  height: 40px;
  padding: 8px;
  margin-top: 30px;
  border-radius: 20px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}
.stripe-pay-btn:hover {
  outline: none;
}
.stripe-pay-btn:active {
  outline: none;
}
.have-coupon-code {
  font-size: inherit;
  font-weight: 500;
  line-height: initial;
  padding-right: 8px;
}

.name-input {
  input {
    display: block;
    width: 100%;
  }
  input:hover {
    outline: none;
  }
  input:active {
    outline: none;
  }
  input:focus {
    outline: none;
  }
  label {
    display: block;
  }
  input::placeholder {
    color: #aab7c4;
    font-weight: 500;
    opacity: 1;
  }
}

.promoElement {
  color: #aab7c4;
  font-weight: 600;
  opacity: 1;
  width: 50%;
  height: 40px;
  line-height: 22px;
  cursor: pointer;
  margin-top: 30px;
  padding: 8px 12px;
  border: 1px solid transparent;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.appliedPromoWrapper {
  @extend .promoElement;
  cursor: default;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .closeBtn {
    background-color: #cfd7df9a;
    border-radius: 50%;
    color: white;
    width: 23px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.plans-card {
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  .trial-heading {
    background: #40cd88;
    height: 48px;
    padding: 10px;
    color: white;
    border-radius: 20px;
  }
  .amount {
    font-size: 30px;
    h1 {
      margin-bottom: 4px;
    }
  }
  .amount-text {
    margin-bottom: 5px;
  }
  .plan-item {
    border: 1px solid #e6e6ee;
    border-radius: 8px;
    margin: 4px;
    padding: 10px;
    cursor: pointer;
  }
  .plan-item:hover {
    border: 1px solid #5f9ff6;
  }
  .active {
    border: 1px solid #5f9ff6;
  }
  .max-plans {
    max-height: 265px;
    overflow-y: scroll;
  }
}
.checkout {
  .off {
    font-size: 30px;
    margin-right: 10px;
    text-align: center;
  }
  .green {
    color: #40cd88;
  }
  .months {
    margin-left: 5px;
  }
}
