.text-center {
  text-align: center;
}
.text-danger {
  color: $red;
}
.d-block {
  display: block;
}
.d-flex-space-between {
  display: flex;
  justify-content: space-between;
}
.d-flex-space-around {
  justify-content: space-around;
}
.m-5 {
  margin: 20px;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-sentence {
  text-transform: capitalize;
}
.cursor-pointer {
  cursor: pointer;
}
.text-green {
  color: green;
}

