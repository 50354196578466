.assessments-container {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  white-space: nowrap;
}
.daily-usage-container {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  white-space: nowrap;
}
.assessments-parent-container {
}

