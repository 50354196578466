body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
  font-family: "HK Grotesk";
  src: local("HK Grotesk"), url(./hk-grotesk/HKGrotesk-Black.otf) format("otf");
}

@font-face {
  font-family: "HK Grotesk Semibold";
  src: local("HK Grotesk Semibold"), url(./hk-grotesk/HKGrotesk-SemiBold.otf) format("otf");
}
